<template>
  <div>
    <!-- Top -->
    <v-container>
      <v-row class="pt-3">
        <v-app-bar
          flat
          dense
          color="transparent"
        >
          <h1 class="text-h5 primary--text">Novidades - Emails</h1>
        </v-app-bar>
        <v-btn
          @click="toCSV"
          class="primary"
          rounded
          dense
        >
          <v-icon
            class="ml-2 pr-2"
            small
            >fas fa-file</v-icon
          >
          Gerar CSV
        </v-btn>
      </v-row>
    </v-container>

    <!-- Dashboard de Emails -->
    <v-container class="w-100">
      <v-row
        class="mb-5 justify-center"
        dense
      >
        <v-col
          v-for="(card, idx) in cards"
          :key="idx"
          cols="12"
          md="5"
        >
          <SimpleCard
            :title="card.title"
            :amount="card.amount"
            :color="card.color"
            :dark="card.dark"
            :icon="card.icon"
            :active="card.active"
            :inactive="card.inactive"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Campo de Inserir registros -->
    <v-container
      style="width: 100%"
      class="pt-8 w-100"
    >
      <v-app-bar
        flat
        dense
        color="transparent"
      >
        <h1 class="text-h5 primary--text">Inserir - Email</h1>
      </v-app-bar>
      <v-row
        class="mb-5 justify-center"
        dense
      >
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="contact.name"
            label="Nome"
            placeholder="Nome completo"
            dense
            rounded
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="contact.email"
            label="Email"
            type="email"
            :rules="[rules.email]"
            placeholder="exemplo@gmail.com"
            outlined
            rounded
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="contact.company_name"
            label="Empresa"
            placeholder="Empresa"
            outlined
            rounded
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-btn
            @click="postEmailNews"
            class="primary"
            rounded
            dense
          >
            Salvar
            <v-icon
              class="ml-2"
              small
              >fas fa-save</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- Tabela de emails -->
    <v-container fluid>
      <Table :data="dataTable">
        <template v-slot:tbody="{ item }">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.company_name }}</td>
          <td class="text-center">{{ item.email }}</td>
          <td class="text-right">{{ item.created_at }}</td>
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
import Table from '@/components/data-table/Table'
import SimpleCard from '@/components/dashboard/SimpleCard'

export default {
  components: {
    Table,
    SimpleCard,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    contact: {},
    cards: [
      {
        title: '10 Dias',
        amount: 0,
        color: 'primary-color',
        dark: true,
        icon: 'fas fa-users',
        active: 0,
        inactive: 0,
      },
      {
        title: 'Último Mês',
        amount: 0,
        color: 'warning-color',
        dark: true,
        icon: 'fas fa-users',
        active: 0,
        inactive: 0,
      },
      {
        title: 'Este Mês',
        amount: 0,
        color: 'warning2-color',
        dark: true,
        icon: 'fas fa-users',
        active: 0,
        inactive: 0,
      },
      {
        title: 'Total',
        amount: 0,
        color: 'total-color',
        dark: true,
        icon: 'fas fa-users',
        active: 0,
        inactive: 0,
      },
    ],
    dataTable: {
      title: 'Emails',
      context: 'newsletter-context',
      headerButtons: {
        filter: false,
        new: false,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Empresa', value: 'company_name' },
        { text: 'Email', value: 'email', width: 400, align: 'center' },
        {
          text: 'Data de Registro',
          value: 'created_at',
          width: 200,
          align: 'end',
        },
      ],
    },
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Insira um email válido'
      },
    },
  }),
  methods: {
    postEmailNews() {
      this.$api
        .post('email_news/store', this.contact)
        .then(() => {
          // Em caso de sucesso, atualiza dashboard e tabela
          this.getDash()
          this.getEmails()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    getEmails() {
      this.$api
        .get('email_news')
        .then((res) => {
          this.dataTable.items = res.data.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    getDash() {
      this.$api
        .get('email_new/info')
        .then((res) => {
          this.cards[0].amount = res.data.data['ten_days']
          this.cards[1].amount = res.data.data['last_month']
          this.cards[2].amount = res.data.data['now_month']
          this.cards[3].amount = res.data.data['total']
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    toCSV() {
      this.$api
        .get('email_new/csv')
        .then((res) => {
          const csvUrl = this.$store.state.hostName + res.data.data
          window.open(csvUrl, '_blank')
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Arquivo CSV exportado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    // Proteção: se o usuário for do tipo "editor", redireciona para /not-authorized
    const userType = Vue.$cookies.get('type') || ''
    if (userType.toLowerCase() === 'editor') {
      this.$router.push('/not-authorized')
      return
    }
    this.getEmails()
    this.getDash()
  },
}
</script>
